import React, { useState } from "react"
import { navigate } from 'gatsby'
import Img from "gatsby-image"
import Link from 'gatsby-link'
import { Helmet } from "react-helmet"
import { FaEthereum, FaPencilAlt } from 'react-icons/fa';
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "../components/layout"
import SEO from "../components/seo"
import icon from "../images/icon.png"

const NavLink = props => {
  if (!props.test) {
    return <Link to={props.url} className={`${props.className} button is-dark`}>{props.text}</Link>
  } else {
    return <span className={`${props.className} button`} disabled>{props.text}</span>
  }
}

const Pager = props => {
  const previousUrl = props.index - 1 === 1 ? '' : (props.index - 1).toString()
  const nextUrl = (props.index + 1).toString()
  const buttonClass = "button is-outlined is-dark button is-rounded is-responsive-small"

  return (
    <nav className={`pagination is-centered ${props.className}`} role="navigation">
      <NavLink test={props.first} url={`/${previousUrl}`} className={`pagination-previous ${buttonClass}`} text="Previous" />
      <NavLink test={props.last} url={`/${nextUrl}`} className={`pagination-next ${buttonClass}`} text="Next" />

      <ul className="pagination-list">
        {!props.first && <li><Link to="/" className={`pagination-link ${buttonClass}`}>1</Link></li>}
        {props.index > 3 && <li><span className="pagination-ellipsis">&hellip;</span></li>}

        {props.index - 1 >= 2 && <li><Link to={`/${props.index - 1}/`} className={`pagination-link ${buttonClass}`}>{props.index - 1}</Link></li>}
        <li><Link to={`/${props.index !== 1 ? props.index : ''}/`} className="pagination-link button is-dark button is-rounded is-responsive-small">{props.index}</Link></li>
        {props.index < (props.pageCount -1) && <li><Link to={`/${props.index + 1}/`} className={`pagination-link ${buttonClass}`}>{props.index + 1}</Link></li>}

        {props.index < (props.pageCount - 2) && <li><span className="pagination-ellipsis">&hellip;</span></li>}
        {!props.last && <li><Link to={`/${props.pageCount}/`} className={`pagination-link ${buttonClass}`}>{props.pageCount}</Link></li>}
      </ul>
    </nav>
  )
}

const Content = ({ children }) => (
  <p className="is-size-5-desktop is-size-6-mobile is-size-6-tablet mb-4">{children}</p>
)

const IndexPage = ({ pageContext, data: { sample, words } }) => {
  const customMeta = [{
    name: `twitter:image`,
    content: `https://www.thedepicted.com${icon}`,
  }];

  const goToRandomWord = (e) => {
    e.preventDefault();
    navigate(`/${words.edges[Math.floor(Math.random() * words.edges.length)].node.id}/`);
  }

  return (
    <Layout title="thedepicted.">
      <Helmet htmlAttributes={{ class: 'bg' }} />
      <SEO title="thedepicted." meta={customMeta} />

      <h2 className="subtitle is-size-2-desktop is-size-4-mobile is-size-4-tablet has-text-black has-text-weight-bold my-6">
        a unique collection of generatively drawn doodle posters, good enough to hang on your wall.
      </h2>

      <div className="columns is-centered">
        <div className="column">
          <Content>each <a href="#" onClick={(e) => goToRandomWord(e)}>poster</a> is generated at the time of minting, using the transactions hash as a seed. this means each piece is dynamically created, unique to you, and can be algorithmically re-created in the event of loss. the poster essentially becomes an artistic representation of your blockchain transaction.</Content>
          <Content>the AI learns over time, meaning the doodles will become more realistic over the generations. first generation posters will be considered rare because of their unique style and 'quirks'. a good example of this is <Link to="/smiley-face/">smiley face</Link> — can you spot it? or <Link to="/finger/">fingers</Link> ... some not quite looking like fingers.</Content>
          <Content>all posters come in two versions; black &amp; white and colorised. you can request a poster in any size and format. collecting words can <strong><Link to="/rewards/">unlock special rewards</Link>.</strong></Content>
          
          <div className="has-text-centered">
            <button onClick={(e) => goToRandomWord(e)} className="button mt-3 mx-2 is-responsive is-dark is-rounded has-text-weight-bold"><FaPencilAlt className="mr-3" />explore a random drawing</button>
          </div>
        </div>

        <div className="column">
          <Img fluid={sample.childImageSharp.fluid} />
        </div>
      </div>

      {/* <iframe allowfullscreen="true" frameborder="0" scrolling="no" src="https://art.kunstmatrix.com/apps/artspaces/index.html?external=true&splashscreen=true&language=en&uid=49465&exhibition=5213332" width="100%" height="600"></iframe> */}
      
      <div className="columns is-multiline mt-6">
        {
          pageContext.group.map((result) => {
            const word = result.node

            return (
              <div key={word.id} className="column">
                <div className="card pt-6">                  
                  <div className="card-image">
                    <figure className="image has-text-centered">
                      <Link to={`/${word.id}/`}>
                        <video autoPlay muted loop playsInline className="has-ratio" width="128" height="128">
                            <source src={`/${word.id}.mp4`} type="video/mp4" />
                        </video>
                      </Link>
                    </figure>
                  </div>

                  <div className="card-content has-text-centered">
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-size-4 is-font-pixel"><Link to={`/${word.id}/`}>{word.word}</Link></p>
                      </div>
                    </div>

                    <Link to={`/${word.id}/`} className="button is-dark is-small is-outlined is-rounded has-text-weight-bold mr-2 mb-2"><FaPencilAlt className="mr-2" />view a sample</Link>
                    {word.forSale && <OutboundLink href={word.opensea} target="_blank" rel="noreferrer" className="button is-link is-small is-rounded has-text-weight-bold mb-2"><FaEthereum className="mr-2" />buy on OpenSea for Ξ{word.price}</OutboundLink>}
                    {!word.forSale && <OutboundLink href="https://discord.gg/jazgg4xr" target="_blank" rel="noreferrer" className="button is-dark is-disabled is-small is-rounded has-text-weight-bold mb-2" disabled><FaEthereum className="mr-3" />drops next week</OutboundLink>}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      <Pager {... pageContext} />

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    sample: file(relativePath: { eq: "sample.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    words: allWord {
      edges {
        node {
          id,
          word
          opensea
          price
          forSale
        }
      }
    }
  }
`